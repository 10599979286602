import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import routes from '../routes';

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>Seite nicht gefunden</h1>
      <Link to={routes.home}>Zur Startseite</Link>
    </div>
  </Layout>
);

export default NotFoundPage;
